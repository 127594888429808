// app/javascript/controllers/nested_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        templateDepth: { type: Number, default: 0 }
    }

    static targets = [
        "add_item", 
        "template", 
        "insertedElement"
    ]

    connect() {
        //console.log(this.templateIdValue);
    }

    replaceTemplateValue(input) {
        var newID = String(new Date().valueOf());
        newID += String(Math.floor(Math.random() * 1000));


        var string = "TEMPLATE_RECORD"
        string += "_"+this.templateDepthValue;

        const regex = new RegExp(string, "g");
        return input.replace(regex, newID)
    }

    add_association(event) {
        event.preventDefault();
        this.add_association_raw();
    }

    add_association_raw() {
        var content = this.replaceTemplateValue(this.templateTarget.innerHTML)
        this.add_itemTarget.insertAdjacentHTML('beforeend', content)
        //console.log(this.add_itemTarget);
        //console.log(content);
        this.dispatch("elementAdded", { detail: { element: this.add_itemTarget.lastElementChild }});
    }

    add_association_inplace(event) {
        event.preventDefault();
        var content = this.replaceTemplateValue(this.templateTarget.innerHTML)
        var inPlaceElement = event.target.closest('.nested-fields')
        inPlaceElement.insertAdjacentHTML('afterend', content)
    }

    add_number_of_associations(numberOfAssociations) {
        console.log("Adding a number of associations: "+numberOfAssociations);
        for(var i = 0; i < numberOfAssociations; i++) {
            this.add_association_raw();
        }
    }

    remove_association(event) {
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        if(item.querySelector("input[name*='_destroy']")) {
            item.querySelector("input[name*='_destroy']").value = '1'
            item.classList.add("hidden");
        } else {
            item.remove();
        }
    }
}
