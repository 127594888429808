/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'input', 
        'container'
    ]

    connect() {
        console.log("Connecting To 'Hide On Input' controller")
        this.handleContainerDisplay();
    }
    
    handleContainerDisplay() {
        var currentValue = this.inputTarget.checked;
        console.log("Current HOI value: "+currentValue);
        
        switch(currentValue) { 
            case false:
                this.containerTarget.style.display = "none";
                break;
            case true:
                this.containerTarget.style.display = "block";
                break;
        }
    }
}
