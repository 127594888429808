/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["category_name", "autocomplete_suggestion_block"];

    connect() {
        console.log("Connected");
    }

    autocomplete(event) {
        switch(event.key) {
            case "ArrowDown":
                this.moveSuggestionDown();
                break;
            case "ArrowUp":
                this.moveSuggestionUp();
                break;
            case "Enter":
                this.closeAutoComplete();
                event.preventDefault();
                return false;
                break;
            default:
                var results = this.fetchOptions();
                this.render(results);
        }

    }

    stopEnter(event) {
        if(event.key == "Enter") {
            if(this.getCurrentOptions().length > 0) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        }
    }

    fetchOptions() {
        const input 	= this.category_nameTarget.value;
        const options 	= JSON.parse(this.category_nameTarget.getAttribute("data-autocomplete-source"));
        window.options = options
        var results = options.filter(function(option) {
            return option.toLowerCase().includes(input.toLowerCase())
        });
        this.autocomplete_suggestion_blockTarget.setAttribute("data-currentSelection", null);

        return results;
    }

    render(options) {
        var suggestions_html = "<ul>"
        options.forEach(function(element) {
            suggestions_html += "<li data-action='click->custom-field#clickSuggestion'>"+element+"</li>"
        });
        suggestions_html += "</ul>"
        this.autocomplete_suggestion_blockTarget.innerHTML = suggestions_html
        this.autocomplete_suggestion_blockTarget.style.display = 'block';
    }

    clickSuggestion(event) {
        var selected_text = event.target.innerText;
        this.category_nameTarget.value = selected_text;
        this.closeAutoComplete();
    }

    moveSuggestionDown() {
        var optionsText = this.getCurrentOptionsText();
        var options     = this.getCurrentOptions();
        var position = parseInt(this.autocomplete_suggestion_blockTarget.getAttribute("data-currentSelection"));

        if(isNaN(position)) {
            position = -1;
        }

        position += 1;
        if(position >= options.length) {
            position = options.length - 1;
        }

        this.autocomplete_suggestion_blockTarget.setAttribute("data-currentSelection", position);
        this.category_nameTarget.value = optionsText[position];
        this.setSelected();
    }

    moveSuggestionUp() {
        var optionsText = this.getCurrentOptionsText();
        var options     = this.getCurrentOptions();
        var position = parseInt(this.autocomplete_suggestion_blockTarget.getAttribute("data-currentSelection"));

        if(isNaN(position)) {
            position = options.length;
        }

        position -= 1;
        if(position < 0) {
            position = 0;
        }

        this.autocomplete_suggestion_blockTarget.setAttribute("data-currentSelection", position);
        this.category_nameTarget.value = optionsText[position];
        this.setSelected();
    }

    getCurrentOptionsText() {
        return this.getCurrentOptions().map(function(e) {
            return e.innerText;
        });
    }

    getCurrentOptions() {
        return Array.from(this.autocomplete_suggestion_blockTarget.firstElementChild.children)
    }

    setSelected() {
        var options = this.getCurrentOptions()
        var position = parseInt(this.autocomplete_suggestion_blockTarget.getAttribute("data-currentSelection"));
        options.forEach(function(element) {
            element.classList.remove('selected');
        })
        if(isNaN(position) == false) {
            options[position].classList.add('selected');
        }
    }

    closeAutoComplete() {
        setTimeout(function(stimulus_context) {
            stimulus_context.autocomplete_suggestion_blockTarget.style.display = 'none';
        }, 100, this);
    }
}
