// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "tableBody"
    ];

    connect() {
        console.log("Connected to activity log controller");
        this.loadTable();
    }

    pillClick(event) {
        var clicked = event.target;
        this.managePill(clicked, clicked.checked);
    }

    managePill(clicked, checked) {
        //console.log("Pill Click");
        console.log(this.data.get("preferenceUrl"));
        console.log("Key: "+clicked.dataset["pillKey"]);
        console.log("Value: "+checked);

        var outsideThis = this;

        fetch(this.data.get("preferenceUrl"),
            { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
                },
                body: JSON.stringify({
                    key: clicked.dataset["pillKey"],
                    value: checked ? "1" : "0"
                }),
                credentials: 'same-origin'

            }
        ).then(function(response) {
            console.log("Setting - Loading table");
            outsideThis.loadTable();
        }).catch(function(error) {
            console.log("Error - Could not set");
        });
    }

    loadTable() {
        var tbt = this.tableBodyTarget;
        console.log(document.querySelector("#activity_log_selected_date").dataset.selectedDate)
        fetch( 
            this.data.get("tableUrl") +
            "?activity_log_date=" +
            document.querySelector("#activity_log_selected_date").dataset.selectedDate
        ).then(function(response) {
            return response.text();
        }).then(function(html) {
            //console.log(html);
            tbt.innerHTML = html;
        });
    }

    changeDate(event) {
        console.log("Changing Date");
        console.log(event.target);
    }
}
