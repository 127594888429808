import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "addButton",
        "removeButton",
        "hiddenValue"
    ]

    removeButtonTargetConnected() {
        this.setAddRemoveButtons();
    }

    setAddRemoveButtons() {
        //console.log("Hidden Value Targets: " + this.hiddenValueTargets.length);
        var visibleRows = []
        this.hiddenValueTargets.forEach( (row) => {
            visibleRows.push(row.closest(".nested-fields"));
        });

        visibleRows = visibleRows.filter( (row) => {
            return !row.classList.contains("hidden");
        })
        //console.log("Visible rows: "+visibleRows.length);

        // Add
        this.addButtonTargets.forEach((button) => {
            button.classList.add("hidden");
        });
        this.addButtonTargets[this.addButtonTargets.length - 1].classList.remove("hidden");

        // Remove
        if(visibleRows.length == 1) {
            visibleRows[0].querySelector("button[data-manager--rota-assignments-target='removeButton']").classList.add("hidden");
            visibleRows[0].querySelector("button[data-manager--rota-assignments-target='addButton']").classList.remove("hidden");
        } else {
            visibleRows[0].querySelector("button[data-manager--rota-assignments-target='removeButton']").classList.remove("hidden");
            visibleRows[visibleRows.length - 1].querySelector("button[data-manager--rota-assignments-target='addButton']").classList.remove("hidden");
        }
    }
}
