/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
    ];

    connect() {
    }

    mousein() {
        $(this.element).popover('show');
    }

    mouseout() {
        $(this.element).popover('hide');
    }
}
