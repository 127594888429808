import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        'inputField',
        'inputRow',
        'activeMarker'
    ];

    connect() {
        //console.log("Connected to enter button handler");
    }

    inputButtonPressed(event) {
        console.log("Enter pressed");
        event.preventDefault();

        var currentInput = event.srcElement;
        var currentRow = currentInput.closest('[data-table-input-enter-target="inputRow"]');
        var rowChildren =  Array.from(currentRow.querySelectorAll('[data-table-input-enter-target="inputField"]'));
        var indexOfInput = rowChildren.findIndex(function(e) { return e.contains(currentInput) });

        //console.log(currentRow);
        //console.log(rowChildren);
        //console.log(indexOfInput);

        var tableRows = Array.from(this.inputRowTargets);
        tableRows = tableRows.filter(function(row) {
            
            console.log(row.querySelector('[data-table-input-enter-target="activeMarker"]').textContent);
            return (row.querySelector('[data-table-input-enter-target="activeMarker"]').textContent == "true") || 
                row.querySelector('[data-table-input-enter-target="activeMarker"]').checked;
        });
        var indexOfRow = tableRows.findIndex(function(e) { return e.contains(currentRow) });

        if( ( indexOfRow+1 ) < tableRows.length ) {
            var targetRow = tableRows[indexOfRow + 1];
            var targetRowChildren =  Array.from(targetRow.querySelectorAll('[data-table-input-enter-target="inputField"]'));
            targetRowChildren[indexOfInput].focus();
            targetRowChildren[indexOfInput].select();
        }
    }
}
