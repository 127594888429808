/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "toActivate"
    ]

    inputChanged() {
        this.toActivateTarget.checked = true;

    }
}
