/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "definedUse",
        "definedUseAnswerContainer",
        "definedUseAnswerHeader",
        "associatedQuestion",
        "question"
    ];

    connect() {
        console.log("Connected to booking controller");
        this.changedDefinedUse();
    }

    changedDefinedUse() {
        console.log("Change of defined use")


        this.definedUseTargets.forEach((definedUse) => {
            if(definedUse.checked) {
                var hasQuestion = definedUse.dataset["associatedQuestion"];
                console.log("Has Question: "+hasQuestion);
                if(hasQuestion == 'true') {
                    var question = definedUse.dataset["question"];
                    console.log("Question: " + question);
                    this.definedUseAnswerHeaderTarget.innerHTML = question;
                    this.definedUseAnswerContainerTarget.style = 'display: block';
                } else {
                    console.log("Doesn't have a question");
                    this.definedUseAnswerHeaderTarget.innerHTML = "";
                    this.definedUseAnswerContainerTarget.style = 'display: none';
                }
            }
        });
    }
}
