/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "bookingOpenerSection",
        "bookingOpenerDefinedUsesSection",
        "bookingRestrictionSection",
        "schemeSection"
    ];

    connect() {
        console.log("Connected");
        this.hideAll();
    }

    schemeChanged(elem) {
        this.hideAll();
        switch(elem.srcElement.value) {
            case "booking_opener":
                this.bookingOpenerSectionTarget.style.display = "block";
                break;
            case "booking_opener_facility_defined_uses":
                this.bookingOpenerDefinedUsesSectionTarget.style.display = "block";
                break;
            case "booking_restriction":
                this.bookingRestrictionSectionTarget.style.display = "block";
                break;
        }
    }

    hideAll() {
        this.schemeSectionTargets.forEach(function(e) {
            e.style.display = "none";
        });
    }
}
