/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "continuousPeriod",
        "continuousDayToBillContainer",
        "openFulfilment",
        "openFulfilmentContainer",
        "requiresOrderingContainer",
        "serviceClass",
        "imitateFacility",
        "serviceSlot"
    ];

    connect() {
        this.handleDayToBillDisplay();
        this.handleOpenFulfilmentDisplay();
        this.handleImitateFacilityDisplay();
        this.handleServiceClassDisplay();
    }

    handleServiceClassDisplay() {
        console.log("Handling service class display");

        var serviceClass = this.serviceClassTarget.value;
        if(serviceClass == "Continuous") {
            $('#admin_services_continous_section').show();
            $('#service_start_time_section').hide();

            this.handleOpenFulfilmentDisplay();
            this.requiresOrderingContainerTarget.style.display = "block";
        } else if(serviceClass == "Discrete") {
            $('#admin_services_continous_section').hide();
            $('#service_start_time_section').show();

            this.handleOpenFulfilmentDisplay();
        } else {
            $('#admin_services_continous_section').hide();
            $('#service_start_time_section').hide();

            this.requiresOrderingContainerTarget.style.display = "none";
        }
    }

    handleDayToBillDisplay() {
        if(this.continuousPeriodTarget.value.includes("Anniversary")) {
            this.continuousDayToBillContainerTarget.style.display = "none";
        } else {
            this.continuousDayToBillContainerTarget.style.display = "block";
        }
    }

    handleOpenFulfilmentDisplay() {
        if(this.openFulfilmentTarget.value.includes("required")) {
            this.openFulfilmentContainerTarget.style.display = "none";
            this.requiresOrderingContainerTarget.style.display = "none";
        } else {
            this.openFulfilmentContainerTarget.style.display = "block";
            this.requiresOrderingContainerTarget.style.display = "block";
        }
    }

    handleImitateFacilityDisplay() {
        console.log("Handle imitate facility display");
        if( this.imitateFacilityTarget.value == "true" ) {
            $('#admin_service_predetermined_start_block').hide();
            $('#admin_services_continous_section').hide();
            $('#facility_form_section').show();
            $('#set_availability_dates_section').hide();
            $("#service_allow_time_to_be_chosen").parent().parent().hide();
            $("#service_form_section").hide();
        } else {
            $('#facility_form_section').hide();
            $('#set_availability_dates_section').show();
            $("#service_allow_time_to_be_chosen").parent().parent().show();
            $("#service_form_section").show();
        }
    }
}
