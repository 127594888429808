/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "submitButton", 
        "newItemOptions", 
        "newOptions", 
        "newCostOptions", 
        "accountCode",
        "costAccountCode",
        "loading",
        "complete",
        "failed",
        "jsErrorBox"
    ]

    static values = {
        salesCode: String,
        purchaseCode: String
    }

    connect() {
        console.log("Connecting AMP");

        if(this.accountCodeTarget.options[this.accountCodeTarget.selectedIndex].value == 'new') {
            this.submitButtonTarget.style.display = "inline-block";
            this.newOptionsTarget.style.display = "inline-block";
        } else {
            this.submitButtonTarget.style.display = "none";
            this.newOptionsTarget.style.display = "none";
        }
    }

    itemCodeChanged() {
        this.submitButtonTarget.style.display = "block";
        console.log("Item code changing");

        if(event.target.options[event.target.selectedIndex].value == 'new') {
            this.newItemOptionsTarget.style.display = "block";
        } else {
            this.newItemOptionsTarget.style.display = "none";

            //Cost Code
            var costAccountCode = event.target.options[event.target.selectedIndex].dataset['purchaseCode'];
            console.log("Setting purchase code value: "+costAccountCode);
            if(!(costAccountCode === undefined)) {
                console.log("Setting purchase code value: "+costAccountCode);
                this.costAccountCodeTarget.value = costAccountCode;
            }

            //Sales Code
            var salesAccountCode = event.target.options[event.target.selectedIndex].dataset['salesCode'];
            console.log("Setting sales code value: "+salesAccountCode);
            if(!(salesAccountCode === undefined)) {
                console.log("Setting sales code value: "+salesAccountCode);
                this.accountCodeTarget.value = salesAccountCode;
            }
        }
    }

    codeChanged() {
        this.submitButtonTarget.style.display = "block";

        if(event.target.options[event.target.selectedIndex].value == 'new') {
            this.newOptionsTarget.style.display = "block";
        } else {
            this.newOptionsTarget.style.display = "none";
        }
    }

    costCodeChanged() {
        this.submitButtonTarget.style.display = "block";

        if(event.target.options[event.target.selectedIndex].value == 'new') {
            this.newCostOptionsTarget.style.display = "block";
        } else {
            this.newCostOptionsTarget.style.display = "none";
        }
    }

    onUpdateSuccess(event) {
        event.preventDefault();
        console.log("Update Success");
		var data = event.detail["data"];
		var status = event.detail["status"];
		var xhr = event.detail["xhr"];
		var error_message = data["error_message"];
		var update = data["update"];

        //console.log(data);
        //console.log(update);
        //console.log(xhr);

        if(update == "success") {
            this.jsErrorBoxTarget.style.display = "none";
            if(data["is_new"] == true) {
                console.log(data["redirect_url"]);
                window.location.href = data["redirect_url"];
            } else {
                $(this.submitButtonTarget).fadeOut(1000);
                if( data["update"] == "success" ) {
                    this.newOptionsTarget.style.display = "none";

                    this.completeTarget.style.display = "block";
                    this.failedTarget.style.display = "none";
                    $(this.completeTarget).fadeOut(6000);
                } else {
                    this.failedTarget.style.display = "block";
                    this.completeTarget.style.display = "none";
                    $(this.failedTarget).fadeOut(6000);
                }
            }
        } else {
            console.log("FAILED");
            console.log({error_message});

            this.jsErrorBoxTarget.innerHTML = error_message;
            this.jsErrorBoxTarget.style.display = "block";
        }
    }
}
