/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = [ 
        "exclusiveContainer",
        "exclusiveOption",
        "exclusiveOptionContainer",
        "definedUse"
    ];

    connect() {
        console.log("Exclusive booking controller");
        if( this.definedUseTargets.length > 0 ) {
            this.definedUseTargets.forEach((definedUse) => {
                if(definedUse.checked) {
                    this.setExclusiveOption(definedUse.dataset["exclusiveBooking"]);
                }
            });
        }
    }

    changedDefinedUse(elem) {
        console.log("Defined use changed");
        this.setExclusiveOption(elem.currentTarget.dataset["exclusiveBooking"]);
    }

    setExclusiveOption(val) {
        if( val == "true") {
            this.exclusiveOptionTargets[1].checked = true
        } else {
            this.exclusiveOptionTargets[0].checked = true
        }
    }
}
