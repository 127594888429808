/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        scoopWeight: Number
    }
    static targets = [ 
        "scoopInput",
        "weightInput",
    ];

    connect() {
        this.convertToScoops();
    }

    convertToWeight() {
        let scoops    = Number(this.scoopInputTarget.value);
        let scoopSize = this.scoopWeightValue;
        let weight    = scoops * scoopSize;

        if( isNaN(weight) == false) {
            this.weightInputTarget.value = weight.toFixed(2);
        }
    }

    convertToScoops() {
        if( this.hasScoopInputTarget ) {
            let size        = Number(this.weightInputTarget.value);
            let scoopSize  = this.scoopWeightValue;
            let portionOfScoop = Number((size / scoopSize));

            if( isNaN(portionOfScoop) == false ) {
                this.scoopInputTarget.value = portionOfScoop.toFixed(2);
            }
        }
    }
}
