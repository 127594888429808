/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "period"
    ];

    connect() {
        console.log("Work Note Connection");
        this.setVisibleOption();
    }

    setVisibleOption() {
        var periodType = this.periodTarget.options[
            this.periodTarget.selectedIndex
        ].value;
        console.log("Period Type: "+periodType);

        if(periodType == "single") {
            $("#work_note_single").show();
            $("#work_note_recurring_weekly").hide();
        } else if(periodType == "week") {
            $("#work_note_single").hide();
            $("#work_note_recurring_weekly").show();
        }
    }
}
