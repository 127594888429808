// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'startTime',
    'endTime',
    'startTimeDisplay',
    'endTimeDisplay',
    'dateDisplay',
    'extendButton',
    'display'
  ];

  connect() {
    console.log("Connected to booking time controller");
    this.manageExtendDisplay();
  }

  manageExtendDisplay() {
    this.getNextSlot().then(slotData => {
      if (slotData.available) {
        this.extendButtonTarget.setAttribute("style", "display: inline-block;");
      } else {
        this.extendButtonTarget.setAttribute("style", "display: none;");
      }
    }).catch(error => {
      console.log("ERROR");
      console.log(error);
    });
  }

  getNextSlot() {
    var startTime = this.startTimeTarget.value;
    var endTime = this.endTimeTarget.value;
    var url = this.data.get("url") + "?start_time=" + startTime + "&end_time=" + endTime;

    console.log("URL");
    console.log(url);

    return fetchATYPlain(url).then(response => response.json())
  }

  applyNextSlot(event) {
    event.preventDefault();
    this.extendButtonTarget.setAttribute("style", "display: none;");
    console.log("Applying Next Slot");
    this.getNextSlot().then(slotData => {
      console.log("Slot Data: " + slotData);
      if (slotData.available) {
        console.log("Changing");
        this.endTimeTarget.value = slotData.endTime;
        this.displayTarget.innerHTML = slotData.display;
      }
      this.manageExtendDisplay();
    });
  }
}
