import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "rotaForm",
        "addRotaTaskButton"
    ]

    connect() {
        this.renumber();

    }

    manualRenumber(event) {
        event.preventDefault();
        this.renumber();
    }

    renumber() {

        console.log("REWORKING");

        console.log( this.rotaFormTargets.length );

        var primaryRotaTasksLength = this.rotaFormTargets[0].querySelectorAll(".rota-task").length;

        console.log("Rota Forms Length: "+this.rotaFormTargets.length);

        for(var i = 1; i < this.rotaFormTargets.length; i++) {
            console.log("Rota Form: "+i);
            var rotaForm = this.rotaFormTargets[i];

            var rotaTasksLength = rotaForm.querySelectorAll(".rota-task").length;
            console.log("Rota Tasks Length: "+rotaTasksLength);
            var needed = primaryRotaTasksLength - rotaTasksLength;
            console.log("Needed: "+needed);

            const event = new CustomEvent("addRotaTasks", { details: { needed: needed } });
            rotaForm.dispatchEvent(event);
            
            // for(var j = 0; j < needed; j++) {
            //     console.log("Clicking");
            //     this.addRotaTaskButtonTargets[i].click();
            // }
        }

    }
}

