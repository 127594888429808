/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["max_horses", "horse_data_link"];

    connect() {
        console.log("Connecting");
        this.max_horses_changed();
        $("#user_horse_fields_container fieldset").each(function(index, value) {
            $(this).find(".horse_number").text(ordinalize(index+1))
        });
    }

    max_horses_changed() {
        if($('#user_horse_fields_container').length) {
            var max_horse_val = parseInt(this.max_horsesTarget.value);

            if( max_horse_val < 0 ) {
                $('#user_max_horses').val("0")
            } else {
                do {
                    var num_horse_fields = parseInt($('#user_horse_fields_container fieldset').size())
                    if( max_horse_val > num_horse_fields ) {
                        var field_data = $("#horses_field_data_link").data('fields')
                        var field_id = $("#horses_field_data_link").data('id')

                        var regexp = new RegExp(field_id, 'g')
                        $("#user_horse_fields_container").append(
                            field_data.replace(regexp, num_horse_fields)
                        )

                        // Fill in the horse number
                        $("#user_horse_fields_container fieldset:last-child .horse_number").text(ordinalize(num_horse_fields+1))

                    } else if (max_horse_val < num_horse_fields) {
                        $("#user_horse_fields_container fieldset:last-child").remove()
                    }
                } while ( max_horse_val != num_horse_fields )
            }
        }
    }

    add_phonenumber_field(event) {
        var field_data  = $("#user_phone_numbers_field_data_link").data('fields')
        var field_id    = $("#user_phone_numbers_field_data_link").data('id')
        var num_phone_number_fields = parseInt($('#admin_edit_user_phone_numbers_holder tbody tr').size())
        var regexp = new RegExp(field_id, 'g')

        var new_field = field_data.replace(regexp, num_phone_number_fields)
        $("#admin_edit_user_phone_numbers_holder tbody").append(new_field)

        if(event) { event.preventDefault(); }
    }

    remove_phonenumber_field(event) {
        var element = $(event.target)
        var $row = element.closest('tr');
        if( $("#admin_edit_user_phone_numbers_holder tbody").children().size() > 0 ) {
            $row.addClass("danger");
            $row.fadeOut(500, function () {
                element.hide();
                $row.find(".destroy_input").val("1");
            });
        }

        event.preventDefault();
    }
}
