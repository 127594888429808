/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container", "destroyField"]
    static values = {
        submitForm: { type: Boolean, default: false }
    }

    connect() {
        //console.log("Connecting Section Removal Controller");
        this.setVisibility();
    }

    remove(event) {
        console.log("Submit Form Value: " + this.submitFormValue);
        if(!this.submitFormValue) {
            event.preventDefault()
        }

        console.log("Removing field");
        if (this.hasDestroyFieldTarget ) {
            console.log("Setting destroy");
            this.destroyFieldTarget.value = 'true'
            this.setVisibility();
        } else {
            this.containerTarget.parentNode.removeChild(this.containerTarget);
        }
    }

    setVisibility() {
        if(
            (this.destroyFieldTarget.value == '1') || 
            (this.destroyFieldTarget.value == 'true') 
        ) {
            this.containerTarget.style.display = 'none';
        }
    }
}
