import NestedFormController from "./nested_form_controller.js"
export default class extends NestedFormController {
    connect(){
    }    

    add_association_inplace(event) {
        event.preventDefault();
        var content = this.replaceTemplateValue(this.templateTarget.innerHTML)
        var inPlaceElement = event.target.closest('.nested-fields')
        inPlaceElement.insertAdjacentHTML('afterend', content)

        //manageChangeWidth();
        setupDatePicker();
        var $row = $(inPlaceElement);
        var old_row = $($("#bulk_subscription_body tr")[$row.index()]);
        var new_row = $($("#bulk_subscription_body tr")[$row.index()+1]);

        new_row.find(".subscription_time_date_field").val(old_row.find(".subscription_time_date_field").val());
        new_row.find(".user_id_field").val(old_row.find(".user_id_field").val());
        new_row.find(".service_id_field").val(old_row.find(".service_id_field").val());

        // Handle changing the horse to the previous ASAP 
        var observer = new MutationObserver(function (mutations, me) {
            var horse_id_field = new_row.find(".horse_id_field");
            if (horse_id_field) {
                horse_id_field.val(old_row.find(".horse_id_field").val());
                me.disconnect(); // stop observing
                return;
            }
        });
        observer.observe(new_row[0], {
            childList: true,
            subtree: true
        });
    }
}
