/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container", "fieldDataLink"]

    connect() {
        console.log("Connecting Rota Form Controller");
    }

    add() {
        console.log("Adding field");

        var field_data  = this.fieldDataLinkTarget.dataset.fields;
        var field_id    = this.fieldDataLinkTarget.dataset.id;

        var regexp  = new RegExp(field_id, 'g')
        var d       = new Date();
        var field_data = field_data.replace(regexp, d.getTime().toString());

        regexp = new RegExp('blank_form_id', 'g')
        field_data = field_data.replace(regexp, d.getTime().toString());

        // Build the containing element & append
        //var div = document.createElement('template');
        //div.innerHTML = field_data.trim();
        //var fragment    = document.createDocumentFragment();
        //fragment.appendChild(div);
        //this.containerTarget.appendChild(fragment.content.firstChild);
        this.containerTarget.insertAdjacentHTML('beforeend',field_data);
    }
}
