/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "checkbox",

    ];

    connect() {
        console.log("Connect to bulk checker");
    }

    selectAll() {
        console.log("Select All");
        this.checkboxTargets.forEach(function(e) {
            console.log("Checking");
            if(e.checked == false) {
                e.parentElement.click();
            }
        });
    }

    selectNone() {
        console.log("Select None");
        this.checkboxTargets.forEach(function(e) {
            console.log("Checking");
            if(e.checked == true) {
                e.parentElement.click();
            }
        });
    }
}
