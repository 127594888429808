/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    tabChange(event) {
        this.element.querySelector("button.activeTabSelector").classList.remove("activeTabSelector");
        var forTab = event.srcElement.dataset['forTab'];
        event.srcElement.classList.add("activeTabSelector");

        var oldTabContent = this.element.querySelector(".activeTab");
        oldTabContent.classList.remove("activeTab");
        oldTabContent.classList.add("inactiveTab");

        var newTabContent = this.element.querySelector("#tab"+forTab);
        newTabContent.classList.remove("inactiveTab");
        newTabContent.classList.add("activeTab");
    }
}
