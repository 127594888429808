/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "itemID",
    "exclusiveBookingContainer"
  ]

  connect() {
    console.log("Connecting Item Publication");
    this.itemChanged();
  }

  itemChanged() {
    console.log("Item Changed");
    if (this.itemIDTarget.selectedOptions[0].dataset["calendarStyle"] == "true") {
      this.exclusiveBookingContainerTarget.style = "display: block;"
    } else {
      this.exclusiveBookingContainerTarget.style = "display: none;"
    }
  }
}
