/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "question",
        "container",
        "start_time",
        "end_time"
    ];

    connect() {
        console.log("Question");
    }

    answerMade(elem)  {
        var startTime = this.data.get("starttime");
        var endTime = this.data.get("endtime");
        var url = this.data.get("url");
        var formName = this.data.get("formname");
        console.log("Form Name: "+formName);

        var questions = this.questionTargets;
        var numQuestions = questions.length;

        var results = {};
        var questionFound = false;
        questions.forEach(function(question, i) {
            console.log("Question: "+i);
            if(questionFound == true ) {
                question.parentNode.removeChild(question);
            } else {
                var questionForElem = elem.currentTarget.closest(".booking_question");
                console.log("CT: "+questionForElem);
                console.log("Q: "+question);
                if(question === questionForElem) {
                    console.log("Found question");
                    questionFound = true;
                }
                var facilityGroupId = question.dataset.questionid;
                var questionValue;
                var radios = question.querySelectorAll("input[name='"+formName+"[group_answers_attributes]["+i+"][answer_id]']");
                radios.forEach(function(radio, i) {
                    if(radio.checked) {
                        questionValue = radio.value;
                    }
                });
                //results[facilityGroupId] = questionValue;
                results[i] = { "answer_id": questionValue, "facility_group_id": facilityGroupId} ;
            }
        });

        console.log(results);

        var group_answers = results;
        url += "?num="+numQuestions+"&group_answers="+encodeURIComponent(JSON.stringify(group_answers))
        url += "&start_time="+encodeURIComponent(startTime)
        url += "&end_time="+encodeURIComponent(endTime)
        console.log(url);

        var container = this.containerTarget;
        fetchATY(url)
            .then(function(data) {
                console.log("data");
                console.log(data);
                container.insertAdjacentHTML("beforeend", data);
            }).catch(function(error) {
                console.log("Error");
                console.log(error);
            });
    }
}
