/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "restrictionSetting",
        "slotsContainer",
        "daysContainer",
    ];

    connect() {
        this.handleOrderResrictionChange();
    }

    handleOrderResrictionChange() {
        if( this.restrictionSettingTarget.value == "restrictedToSlots" ) {
            this.slotsContainerTarget.style = "display: block;"
        } else {
            this.slotsContainerTarget.style = "display: none;"
        }

        if( this.restrictionSettingTarget.value == "restrictedToDaysOfWeek" ) {
            this.daysContainerTarget.style = "display: block;"
        } else {
            this.daysContainerTarget.style = "display: none;"
        }
    }
}
