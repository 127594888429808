/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "allowPartialPurchases",
        "partialPurchaseType",
        "fixedAvailabilityDate",
        "requiresOrdering",
        "hasCostPrice",
        "unit"
    ];

    connect() {
        this.allow_partial_purchases_changed();
        this.requires_ordering_changed();
        this.set_fixed_availability_date_changed();
        this.has_cost_price_changed();
        this.unitChange();
    }

    allow_partial_purchases_changed() {
        if(this.allowPartialPurchasesTarget.checked) {
            $('#partial_purchase_block').show();
            $("#product_requires_ordering").prop('checked', false);
            $("#product_requires_ordering").prop('disabled', true);
        } else {
            $('#partial_purchase_block').hide();
            $("#product_requires_ordering").prop('disabled', false);
        }

        var partialPurchaseTypeVal = this.partialPurchaseTypeTarget.options[
            this.partialPurchaseTypeTarget.selectedIndex
        ].value;

        if(partialPurchaseTypeVal == "Weight") {
            $("#fraction_partial_purchase").hide();
            $("#size_partial_purchase").show();
        } else if(partialPurchaseTypeVal == "Fraction") {
            $("#fraction_partial_purchase").show();
            $("#size_partial_purchase").hide();
        }
    }

    requires_ordering_changed() {
        if(this.requiresOrderingTarget.checked) {
            $('#ordering_block').show();
        } else {
            $('#ordering_block').hide();
        }
    }

    set_fixed_availability_date_changed() {
        if(this.fixedAvailabilityDateTarget.checked) {
            $('#fixed_availability_date_block').show();
        } else {
            $('#fixed_availability_date_block').hide();
        }
    }

    has_cost_price_changed() {
        if(this.hasCostPriceTarget.checked) {
            $('.product_cost_price').show();
        } else {
            $('.product_cost_price').hide();
        }
    }

    unitChange() {
        var unitSelected = this.unitTarget.options[this.unitTarget.selectedIndex].value;
        var sizeLabel = (function(pUnit) {
            switch(pUnit) {
            case "g":
                return "Size (grams)"
                break;
            case "kg":
                return "Size (kilograms)"
                break;
            case "ml":
                return "Volume (milliliters)"
                break;
            case "l":
                return "Volume (litres)"
                break;
            }
        })(unitSelected);
        var scoopLabel = (function(pUnit) {
            switch(pUnit) {
            case "g":
                return "Scoop Size (grams)"
                break;
            case "kg":
                return "Scoop Size (kilograms)"
                break;
            case "ml":
                return "Measure Volume (milliliters)"
                break;
            case "l":
                return "Measure Volume (litres)"
                break;
            }
        })(unitSelected);

        $('label[for="product_size"]').html(sizeLabel);
        $('label[for="product_scoop_size"]').html(scoopLabel);
    }
}
