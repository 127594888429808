/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "billingSection",
        "currentHorse"
    ];

    connect() {
        //console.log("Connected");
        this.horseChanged();
    }

    horseChanged(event) {
        var horseID;
        if(event) {
            //console.log("We Have Event");
            horseID = event.target.getAttribute('id').split('_').pop();
            this.currentHorseTarget.innerHTML = horseID;
        } else {
            //console.log("No Event");
            horseID = this.currentHorseTarget.innerHTML.trim();
        }

        //console.log("Horse Has Changed");
        //console.log("Horse ID: "+horseID);
        //console.log("URL: "+this.data.get("url"));

        if( this.data.get("url") && (horseID != "") ) {
            var url =  this.data.get("url").replace('REPLACE',horseID);
            //console.log(url);
            fetchATY(url).then(html => { this.billingSectionTarget.innerHTML = html })  
        }
    }
}
