/* eslint no-console:0 */
//import Rails from "rails-ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "paymentOptionChoices", 
        "accountOption",
        "method",
        "accountId",
        "form"
    ];

    connect() {
        console.log("Connecting");
    }

    selectAccount(elem) {
        console.log("Account Selected");
        console.log(elem);
        window.www = elem;

        var accountId = elem.currentTarget.dataset['accountId'];
        this.accountIdTarget.value = accountId;

        this.accountOptionTargets.forEach(function(e) {
            e.style.display = "none";
        });

        elem.target.style.display = "block";
        this.paymentOptionChoicesTargets.forEach(function(e) {
            e.style.display = "block";
        });
    }

    selectMethod(elem) {
        event.stopPropagation();
        event.preventDefault()

        console.log("Method selected");
        //Rails.fire(this.formTarget, 'submit')
        var methodValue = elem.currentTarget.dataset['methodValue'];
        this.methodTarget.value = methodValue;
        this.formTarget.submit();
    }
}
