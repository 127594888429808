/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
    ];

    connect() { } 

    changeWidth(elem) {
        var perc = elem.srcElement.value;
        $('#logo-content').css({
           'width':  perc+"%"
        });
    }

    changePosition(elem) {
        var position = elem.srcElement.value;
        console.log("Position: "+position)
        $('#logo_space').css({
           'text-align':  position
        });
    }
}
