/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'trackingField', 
        'weekAllowanceContainer',
        'perWeekContainer'
    ]

    connect() {
        console.log("Connecting To Allowance Type Switcher");
        this.showCorrectContainer();
    }

    swap(event) {
        console.log("Swap");
        event.preventDefault();

        var currentValue = this.trackingFieldTarget.value;
        console.log({currentValue});
        var new_value;
        switch(currentValue) { 
            case "week_allowance":
                new_value = "per_week";
                break;
            case "per_week":
                new_value = "week_allowance"
                break;
        }

        this.trackingFieldTarget.value = new_value;
        this.showCorrectContainer();
    }

    showCorrectContainer() {
        var currentValue = this.trackingFieldTarget.value;

        switch(currentValue) { 
            case "week_allowance":
                this.weekAllowanceContainerTarget.style.display = "block";
                this.perWeekContainerTarget.style.display = "none";
                break;
            case "per_week":
                this.weekAllowanceContainerTarget.style.display = "none";
                this.perWeekContainerTarget.style.display = "block";
                break;
        }
    }
}
