/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String }

    static targets = [
        'allowFreeForm',
        'container'
    ];

    connect() { 
        console.log("Service Option Controller");
        this.displayAnswerSection();
    }

    displayAnswerSection() {
        console.log("Service Option Controller checked");
        console.log(this.allowFreeFormTarget.checked);
        if(this.allowFreeFormTarget.checked == '1') {
            this.containerTarget.style.display = "none";
        } else {
            this.containerTarget.style.display = "block";
        }
    }
}
