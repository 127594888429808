/* eslint no-console:0 */
//import Rails from "rails-ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "field",
        "div",
        "btn"
    ];

    connect() {
        console.log("Connecting - Div Toggler");
        this.set_display(this.btnTarget);
    }

    toggle_and_change_field() {
        var btn = event.target;
        console.log("Toggle");
        console.log(btn);

        event.preventDefault();

        if(this.fieldTarget.value == "true") {
            this.fieldTarget.value = "false";
        } else {
            this.fieldTarget.value = "true";
        }

        this.set_display(btn);
    }

    set_display(btn) {
        if(this.fieldTarget.value == "true") {
            console.log("SHOWING");
            this.divTarget.style.display = "block";
            btn.value =  'Remove Time'
        } else {
            console.log("HIDING");
            this.divTarget.style.display = "none";
            btn.value =  'Add Time'
        }
    }
}
