/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'togglePreferenceState', 
        'preferencePane',
        'productRow',
        'valuePane',
        'contactID',
        'categoryHeader',
        'categoryGroup'
    ];

    /* This is used to set the included products */

    static values = { 
        selfUrl: String,
        prefUrl: String,
    }

    connect() { }

    changeContact() {
        //console.log("Contact changed selects");
        var toVisit = this.selfUrlValue;
        const regex = new RegExp("REPLACE_CONTACT_ID", "g");
        toVisit = toVisit.replace(regex, this.contactIDTarget.value);
        Turbo.visit(toVisit, { action: "replace" })
    }

    togglePreference() {
        if(this.togglePreferenceStateTarget.dataset.productPreferences == "hidden") {
            this.togglePreferenceStateTarget.setAttribute('data-product-preferences', 'visible');

            this.preferencePaneTargets.forEach((el, i) => { el.style.display = ''; });
            this.productRowTargets.forEach((el, i) => { el.style.display = ''; });
            this.categoryHeaderTargets.forEach((el, i) => { el.style.display = ''; });
        } else {
            var url = this.prefUrlValue;
            const regex = new RegExp("REPLACE_CONTACT_ID", "g");
            url = url.replace(regex, this.contactIDTarget.value);

            var rowData = { };
            var closestHider = this.element.getAttribute("data-manager--stock-order-closestHider");

            this.preferencePaneTargets.forEach((el, i) => { el.style.display = 'none'; });
            this.togglePreferenceStateTarget.setAttribute('data-product-preferences', 'hidden');

            this.productRowTargets.forEach((el, i) => { 
                if($(el).find("input:checkbox")[0].checked) {
                    rowData[$(el).closest(closestHider)[0].id.substring(8)] = true;
                    el.style.display = '';
                } else {
                    rowData[$(el).closest(closestHider)[0].id.substring(8)] = false;
                    el.style.display = 'none';
                }
            });
            console.log(rowData);

            // Now we need to send all the information
            $.ajax({
                type: "POST",
                url: url,
                data: {
                    "rowData": rowData,
                    "categoryData": this.buildOrder(),
                },
                success: function(return_date) { }
            });

            this.updateCategoriesDisplay();
        }
    }
    
    setAllToZero() {
        //Trigger change on row so it'll recalculate the biggest value
        var event = document.createEvent('HTMLEvents');
        event.initEvent('setCurrentStockToZero', true, true);
        $( ".product_row" ).each(function() {
          this.dispatchEvent(event);
        });
    }

    updateCategoriesDisplay() {
        console.log("Sending events");
        const childrenChangedEvent = new CustomEvent('childrenChanged');
        this.categoryHeaderTargets.forEach(function (ht) {
          ht.dispatchEvent(childrenChangedEvent);
        });
    }

    moveCategoryUp(event) {
        event.preventDefault();
        var element = event.srcElement.closest("tbody");
        if(element.previousElementSibling) {
          element.parentNode.insertBefore(element, element.previousElementSibling);
        }
        element.scrollIntoView();
    }

    moveCategoryDown(event) {
        event.preventDefault();
        var element = event.srcElement.closest("tbody");
        if(element.nextElementSibling) {
          element.parentNode.insertBefore(element.nextElementSibling, element);
        }
        element.scrollIntoView();
    }

    buildOrder() {
        var order = [];
        this.categoryGroupTargets.forEach(function(group) {
            order.push(group.dataset['catId']);
        });
        return order
    }
}
