import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets =[
        "input",
        "imageContainer"
    ]

    connect() {
        console.log("Image Upload Controller");

    }

    setImage(event) {
        console.log("Setting image");

        this.imageContainerTarget.src = URL.createObjectURL(
            event.target.files[0]
        );
    }
}
