import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'select',
        'display',
        'input',
        'intervalContainer'
    ]

    connect() {
        console.log("Rota Intervals Controller Connected");

        this.renumber();
    }

    manualRenumber(event) {
        event.preventDefault();
        this.renumber();
    }

    renumber() {
        console.log("Renumbering");
        this.intervalContainerTargets.forEach((intervalContainer, index) => {
            this.inputTargets[index].value = index;
            this.displayTargets[index].innerHTML = index + 1;
        });

        this.dispatch("renumbered");
    }
}