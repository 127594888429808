/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "useValue",
        "useTypeSelection",
        "days",
        "needed",
        "startingStock",
        "currentStock",
    ];

    connect() {
        //this.selectBiggestValue();
        this.updateForStockChange();
    }

    handleValueSelect(event) {
        if("use_type" in event.target.dataset) {
            var clickType = event.target.dataset.use_type;
            this.useTypeSelectionTarget.value = clickType;
            this.passRow();
        }
    }

    passRow() {
        var needed_val = null;
        this.useValueTargets.forEach((el, i) => { 
            if(el.dataset.use_type == this.useTypeSelectionTarget.value) {
                $(el).addClass('selected_use_value') 
                needed_val = parseFloat(el.innerHTML.trim());
            } else {
                $(el).removeClass('selected_use_value') 
            }
        });

        var currentStock        = parseFloat(this.currentStockTarget.value);
        if(isNaN(currentStock)) {
            currentStock = 0;
        }
        // Set the field
        var neededNum = Math.max(0 ,(needed_val - currentStock ));
        this.neededTarget.value = neededNum.toPrecision(4);
    }

    selectBiggestValue() {
        var visibleTargets = this.useValueTargets.filter( target => target.style.display == '')
        var biggest = visibleTargets.reduce(function (prev, current) {
               return (parseFloat(prev.innerText) > parseFloat(current.innerText)) ? prev : current
        });
        var clickType = biggest.dataset.use_type;

        this.useTypeSelectionTarget.value = clickType;

        this.passRow();
    }

    updateForStockChange() {
        var deliveriesTotal  = parseFloat(this.element.getAttribute("data-manager--stock-order-row-deliveries"));

        var startingStock   = parseFloat(this.startingStockTarget.innerHTML);
        var currentStock    = parseFloat(this.currentStockTarget.value);
        var deltaStock      = startingStock - currentStock + deliveriesTotal;

        //console.log({startingStock})
        //console.log({currentStock})
        //console.log({deliveriesTotal})
        //console.log({deltaStock})

        if(isNaN(deltaStock)) {
            deltaStock = 0;
        }

        var period_consumption_val = Math.max(deltaStock, 0);

        this.useValueTargets.forEach((el, i) => {
            if(el.dataset.use_type == "period_consumption") {
                el.innerHTML = period_consumption_val.toPrecision(4);
            }
        });
        this.selectBiggestValue();
    }

    updateForDaysChange() {
        var url = this.data.get("single-row-url")+"&days="+this.daysTarget.value;
        var row_index = this.data.get("single-row-child-index");
        //console.log(url);

        fetchATY(url).then(html => {
            html = html.replaceAll('REPLACE_ME', row_index.toString());
            //console.log({html});
            var wrapper= document.createElement('table');
            wrapper.innerHTML = html;
            var row = wrapper.getElementsByTagName('tr')[0];
            this.element.replaceWith(row);
        });
    }

    setCurrentStockToZero() {
        this.currentStockTarget.value = 0;
    }
}
