import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "addRotaTaskInput",
        "nonSubmitButton",
        "rotaTaskName",
        "rotaForm",
        "submissionConfirmation",
        "numOfIntervals"
    ]

    connect() {
        console.log("Rota Controller");
    }

    actualSubmission(event) {
        this.submissionConfirmationTarget.value = "1";
    }

    submitForHarmonisation(event) {
        this.nonSubmitButtonTarget.click();
    }

    addRotaTask(event) {
        this.addRotaTaskInputTarget.value = "1";
    }

    taskNameChanged(event) {
        var src = event.srcElement;
        var form = src.closest(".rota-form");
        var indexInForm = Array.from(form.querySelectorAll(".rota-task-name")).indexOf(src);

        this.rotaFormTargets.forEach((form) => {
            form.querySelectorAll(".rota-task-name")[indexInForm].value = src.value;
        });
    }

    incrementIntervals(event) {
        this.numOfIntervalsTarget.value = (parseInt(this.numOfIntervalsTarget.value) + 1);
        this.nonSubmitButtonTarget.click();
    }

    orderChange(event) {
        console.log("Primary Rota Order Changed");
        console.log(event.detail);

        let oldIndex = event.detail.oldIndex;
        let newIndex = event.detail.newIndex;

        for(let i = 1; i < this.rotaFormTargets.length; i++) {
            let rf = this.rotaFormTargets[i];

            var parent = rf.querySelector("[data-controller='drag-item']")

            console.log(parent.children);
            console.log("Old Index: " + oldIndex + " New Index: " + newIndex);

            if((newIndex) < parent.children.length) {
                if(newIndex < oldIndex) {
                    var row = parent.children[oldIndex];
                    var sibling = parent.children[newIndex];
                    parent.insertBefore(row, sibling);
                } else {
                    var row = parent.children[oldIndex];
                    var sibling = parent.children[newIndex + 1];
                    parent.insertBefore(row, sibling);
                }
            } else {
                console.log("Append Child");
                parent.appendChild(row);
            }
        }

        this.renumberTaskForms();
    }

    renumberTaskForms() {
        for(let i = 1; i < this.rotaFormTargets.length; i++) {
            let rf = this.rotaFormTargets[i];
            var positionFields = rf.querySelectorAll('[data-drag-item-target="positionField"]')
            positionFields.forEach((positionField, index) => {
                positionField.value = index;
            });
        }
    }

}
