/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "decidingBlock",
    ];

    connect() {
        //console.log("Connect to box hider");
    }

    manageDisplay(block) {
        //console.log("Managing Display");
        var visible = 0;
        this.decidingBlockTargets.forEach(function(e) {
            //console.log("Deciding Block");
            //console.log(e.dataset);
            if(e.getAttribute("data-show") == "true") {
                //console.log("adding");
                visible += 1;
            } else {
                //console.log("not adding");

            }
        });

        //console.log(visible);
        //console.log(block);
        if( visible == 0 ) {
            //console.log("Hide");
            block.target.style.display = "none";
        } else {
            //console.log("Show");
            if($("#subCheck").is(":checked")) {
                //console.log("Really Show!");
                block.target.style.display = "inline-block";
            }
        }
    }
}
