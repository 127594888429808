/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "userID",
        "horseID",
        "horseSelectionContainer"
    ]

    connect() {
        this.user_changed();
    }

    user_changed() {
        var userID = this.userIDTarget.value;
        console.log("User ID: "+userID);
        var horseID = '';
        if(this.hasHorseIDTarget) {
            horseID = this.horseIDTarget.value;
        }
        console.log("Horse ID: "+horseID);
        if( userID ) {
            var horse_url = this.data.get("horse_url");
            var horse_url = horse_url.replace("blank_id", userID);
            var horse_url = horse_url.replace("blank_horse_id", horseID);
            console.log(horse_url);

           //setTimeout( () => { 
            fetchATY(horse_url)  
              .then(html => {
                  this.horseSelectionContainerTarget.innerHTML = html;
            });
            //}, 3000);
        } else {
            this.horseSelectionContainerTarget.innerHTML = "";
        }
    }
}
