/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String }

    static targets = [
        'serviceID',
        'ItemOptionID',
        'container'
    ];

    connect() { 
        console.log("Connect");
        this.loadNewOptions();
    }

    loadNewOptions() {
        console.log("Changed");
        var url = this.urlValue.replace(/PLACEHOLDER/g, this.serviceIDTarget.value);
        if(this.hasItemOptionIDTarget) {
            url += "?item_option_id="+this.ItemOptionIDTarget.value;
        }
        console.log("URL: "+url);
        fetchATY(url)  
            .then(html => {
                this.containerTarget.innerHTML = html;
            });

    }
}
