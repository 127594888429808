/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        'productID',
        'quantityFieldCollection',
        'sizeWeightFieldCollection',
        'sizeFractionFieldCollection',
        'sizeUnit'
    ];

    connect() {
        console.log("Connecting to PurcahseRowController");
        this.displayAmountField();
    }

    displayAmountField() {
        var productType = this.productIDTarget.options[this.productIDTarget.selectedIndex].dataset['type'];
        var displayUnit = this.productIDTarget.options[this.productIDTarget.selectedIndex].dataset['unit'];

        this.sizeUnitTarget.textContent = displayUnit;

        switch(productType) {
            case "Full":
                this.quantityFieldCollectionTarget.style.display        = "table";
                this.sizeWeightFieldCollectionTarget.style.display      = "none";
                this.sizeFractionFieldCollectionTarget.style.display    = "none";
                break;
            case "PartialWeight":
                this.quantityFieldCollectionTarget.style.display        = "none";
                this.sizeWeightFieldCollectionTarget.style.display      = "table";
                this.sizeFractionFieldCollectionTarget.style.display    = "none";
                break;
            case "PartialFraction":
                this.quantityFieldCollectionTarget.style.display        = "none";
                this.sizeWeightFieldCollectionTarget.style.display      = "none";
                this.sizeFractionFieldCollectionTarget.style.display    = "table";
                break;
            default:
                this.quantityFieldCollectionTarget.style.display        = "none";
                this.sizeWeightFieldCollectionTarget.style.display      = "none";
                this.sizeFractionFieldCollectionTarget.style.display    = "none";
                break;
        }
    }
}
