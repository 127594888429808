/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "setEndDate"
    ];

    connect() {
        this.set_end_date_changed();
    }

    set_end_date_changed() {
        if(this.setEndDateTarget.checked) {
            $('#recurring_fulfilment_offers_end_date_block').show();
        } else {
            $('#recurring_fulfilment_offers_end_date_block').hide();
        }
    }
}
