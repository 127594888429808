// app/javascript/controllers/nested_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        templateDepth: { type: Number, default: 0 }
    }

    static targets = [
        "itemContainer", 
        "template", 
        "existingElement",
        'select'
    ]

    connect() {
        if(this.hasSelectTarget) {
            this.setToNumberOfAssociations();
        }

    }

    replaceTemplateValue(input, newID) {
        // var newID = String(new Date().valueOf());
        // newID += String(Math.floor(Math.random() * 1000));


        var string = "TEMPLATE_RECORD"
        string += "_"+this.templateDepthValue;

        const regex = new RegExp(string, "g");
        return input.replace(regex, newID)
    }

    add_association(associationNum) {
        var content = this.replaceTemplateValue(this.templateTarget.innerHTML, associationNum)
        this.itemContainerTarget.insertAdjacentHTML('beforeend', content)
        //console.log(this.itemContainerTarget);
        //console.log("Added");
    }

    setToNumberOfAssociations() {
        var numberOfAssociations = parseInt(this.selectTarget.options[this.selectTarget.options.selectedIndex].dataset['numberOfAssociations']);
        if(isNaN(numberOfAssociations)) {
            numberOfAssociations = parseInt(this.selectTarget.options[this.selectTarget.options.selectedIndex].value);
        }
        if(isNaN(numberOfAssociations)) {
            numberOfAssociations = parseInt(this.selectTarget.value);
        }
        //console.log("Number of Associations: "+numberOfAssociations);

        this.setToDefinedNumberOfAssociations(numberOfAssociations);
    }

    setToDefinedNumberOfAssociations(numberOfAssociations) {
        var existing = this.existingElementTargets.length;
        //console.log("Existing: "+existing);

        // This handles the situation where nothing is selected
        if (isNaN(numberOfAssociations)) {
            numberOfAssociations = 1;
        }

        // Calculate the delta
        var neededChange = numberOfAssociations - existing;
        //console.log("Needed Change: "+neededChange);

        if(neededChange > 0) {
            for(var i = 0; i < neededChange; i++) {
                this.add_association( existing + i );
            }
        } else if(neededChange < 0)  {
            for(var i = 0; i > neededChange; i--) {
                //console.log("Removing");
                this.existingElementTargets[this.existingElementTargets.length-1].remove();
            }
        }

        //console.log(numberOfAssociations);
        // This can be used to process the created elements
        this.dispatch("createdAssociation");
    }
}
