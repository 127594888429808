/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'enableCostPrice',
        'enableSalesPrice',
        'costPriceField',
        'salesPriceField'
    ]


    connect() { 
        this.costPriceCheckbox();
        this.salesPriceCheckbox();
    }

    costPriceCheckbox() {
        if(this.enableCostPriceTarget.checked) {
            this.costPriceFieldTargets.forEach(function(e) {
                e.style = "display:table-cell;"
            });
        } else {
            console.log( this.costPriceFieldTargets);
            this.costPriceFieldTargets.forEach(function(e) {
                console.log("CHANGING");
                e.style = "display:none;"
            });
        }
    }

    salesPriceCheckbox() {
        if(this.enableSalesPriceTarget.checked) {
            this.salesPriceFieldTargets.forEach(function(e) {
                e.style = "display:table-cell;"
            });
        } else {
            this.salesPriceFieldTargets.forEach(function(e) {
                e.style = "display:none;"
            });
        }
    }
}
