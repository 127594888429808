/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["userAssignment"]

    connect() {
        //console.log("rota_autofill_row_controller connected");
    }

    autoFillTrigger(event) {
        console.log("Filling")
        console.log(event.detail.startOffset)

        this.userAssignmentTargets.forEach((userAssignment, index) => {
            var selectedIndex = (event.detail.startOffset + index) % (userAssignment.options.length - 1) + 1;
            console.log("Filling " + selectedIndex);
            userAssignment.options.selectedIndex = selectedIndex;
        });
    }
}
