/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'paymentDetailInput', 'contactID' ];

    connect() {
        console.log("Connected PR controller");
        this.contactChanged();
    }

    contactChanged() {
        console.log("Contact changed");
        var contactID;

        var dpd = this.contactIDTarget.selectedOptions[0].dataset["defaultpaymentdetails"];
        if( dpd ) {
            this.paymentDetailInputTarget.value = dpd;
        } else {
            this.paymentDetailInputTarget.value = "";
        }
    }
}
