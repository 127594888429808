/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "accountCode", 
        "costAccountCode", 
        "submitButton" 
    ]

    connect() {
        console.log("Connecting AMPB");
    }

    copyToAll() {
        if(confirm("Are you sure you want to copy this to all items in this section and save them?")) { 
            console.log("Copying to all")
            var val = this.accountCodeTargets[0].value;
            console.log("Val: "+val);
            this.accountCodeTargets.forEach(function(element) {
                element.value = val;
                element.dispatchEvent(new Event("change"));
            });
            this.submitButtonTargets.forEach(function(element) {
                element.click();
            });
        }
    }

    copyToAllCost() {
        if(confirm("Are you sure you want to copy this to all items in this section and save them?")) { 
            console.log("Copying to all")
            window.cat  = this;
            var val = this.costAccountCodeTargets[0].value;
            console.log("Val: "+val);
            this.costAccountCodeTargets.forEach(function(element) {
                element.value = val;
                element.dispatchEvent(new Event("change"));
            });
            this.submitButtonTargets.forEach(function(element) {
                element.click();
            });
        }
    }
}
