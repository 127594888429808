
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "user",
        "service",
        "memberType"
    ]

    connect() {
        console.log("User Group Membership Controller");
        this.memberTypeChanged();
    }

    memberTypeChanged() {
        var selected = this.memberTypeTarget.options[this.memberTypeTarget.selectedIndex].value
        if(selected == "service") {
            this.serviceTarget.classList.remove("hidden");
            this.userTarget.classList.add("hidden");
        } else {
            this.serviceTarget.classList.add("hidden");
            this.userTarget.classList.remove("hidden");
        }
    }
}