/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["monitoredElement"]

    connect() {
        console.log("Connecting");
    }

    elementChanged() {
        this.monitoredElementTarget.style.display = "inline-block";
    }
}
