/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'anchoredTime'
    ];

    connect() { console.log("Connect"); }

    swap() {
        console.log("Swap");
        if(this.anchoredTimeTarget.value == "1") {
            this.anchoredTimeTarget.value = "0";
        } else {
            this.anchoredTimeTarget.value = "1";
        }
        this.manageGreying();
    }

    manageGreying() {
        var elem = $(this.element);
        console.log(elem);
        if(this.anchoredTimeTarget.value == "0") {
            elem.find(".unanchored_time_box").slideDown("fast", function() {
                elem.find(".anchored_time_box").slideUp();
            });
        } else {
            elem.find(".unanchored_time_box").slideUp("fast", function() {
                elem.find(".anchored_time_box").slideDown();
            });
        }
    }
}
