/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        'selectorBlock', 
        'horseSelectionContainer',
        'horseID', 
        'userID',
        'withBoth',
        'withoutUser',
        'withoutHorse',
    ];

    connect() {
        console.log("Connected BTH");

        // Handle loading the billing to option when the horse is loaded
        var thisController = this;
        var observer = new MutationObserver(function (mutations, me) {
            console.log("Mutation occurred");
            console.log(this.horseIDTarget);
            if( typeof thisController.horseIDTarget !== "undefined" ) {
                console.log("Running this");
                thisController.horseChanged();
                //me.disconnect(); // stop observing
                return;
            }
        });

        observer.observe(this.horseSelectionContainerTarget, {
            childList: true,
            subtree: true
        });

        this.horseChanged();
    }

    horseChanged() {
        var horseID;
        var selectorContent;

        var bth, btu;
        if(typeof this.horseIDTarget.selectedOptions == "undefined") {
            bth = this.horseIDTarget.dataset["billToHorseOption"];
        } else {
            bth = this.horseIDTarget.selectedOptions[0].dataset["billToHorseOption"];
        }
        if(typeof this.userIDTarget.selectedOptions == "undefined") {
            btu = this.userIDTarget.dataset["billToUser"];
        } else {
            btu = this.userIDTarget.selectedOptions[0].dataset["billToUser"];
        }

        // Figure out which element to put in select area
        if 
        ( 
            ( bth == "true") &&
            ( btu == "true")
        ) 
        {
            console.log("With Both");
            selectorContent = this.withBothTarget.dataset["fields"]
        }
        else if 
        ( 
            ( bth == "true") &&
            ( btu == "false")
        ) 
        {
            console.log("Without User");
            selectorContent = this.withoutUserTarget.dataset["fields"]
        }
        else if 
        ( 
            ( bth == "false") &&
            ( btu == "true")
        ) 
        {
            console.log("Without Horse");
            selectorContent = this.withoutHorseTarget.dataset["fields"]
        }
        else if 
        ( 
            (typeof bth == "undefined") &&
            (       btu == "true")
        ) 
        {
            console.log("Without Horse");
            selectorContent = this.withoutHorseTarget.dataset["fields"]
        }
        else
        {
            console.log("Unknown");
        }

        console.log("Selector Content: "+selectorContent);

        this.selectorBlockTarget.innerHTML = selectorContent;
    }
}
