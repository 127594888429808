/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'categoryItem', 
        'activeRow',
    ];

    static values = { catId: String }

    connect() {
        console.log("Connected Category")
    }

    setCategoryToZero() {
        //Trigger change on row so it'll recalculate the biggest value
        var event = document.createEvent('HTMLEvents');
        event.initEvent('setCurrentStockToZero', true, true);

        //console.log("Class Name: " +className);
        this.categoryItemTargets.forEach(function(item) {
          item.dispatchEvent(event);
        });
    }

    manageSelfViaChildrenState() {
        console.log("Children have changed"); 

        var count = 0;
        this.activeRowTargets.forEach(function(activeRowCheckbox) {
            if(activeRowCheckbox.checked == true) {
                count++;
            }
        });

        // If the number of active children is 0
        window.c = this;
        if(count == 0) {
            this.element.style.display="none";
        } else {
            this.element.style.display="table-row-group";
        }
    }

    toggleCategoryActiveStates() {
        var count = 0;
        this.activeRowTargets.forEach(function(activeRowCheckbox) {
            if(activeRowCheckbox.checked == true) {
                count++;
            }
        });

        var new_state = !(count > 0);
        this.activeRowTargets.forEach(function(activeRowCheckbox) {
            activeRowCheckbox.checked = new_state;
        });
    }
}
