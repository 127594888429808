/* eslint no-console:0 */
// subscription_dependant_price_row_controller.js 
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "type",
        "percentageField",
        "percentageInput",
        "valueField"
    ];

    connect() {
        console.log("Connected SDP Row Controller");
        this.manageDisplayOfFields();
        this.setupInputMasks();
    }

    manageDisplayOfFields() {
        switch(this.typeTarget.value)  {
            case "percentage":
                this.percentageFieldTarget.style = "display: table";
                this.valueFieldTarget.style = "display: none";
                break;
            case "value":
                this.percentageFieldTarget.style = "display: none";
                this.valueFieldTarget.style = "display: table";
                break;
            default:
        }

    }

    swapDiscountType(event) {
        switch(this.typeTarget.value)  {
            case "percentage":
                this.typeTarget.value = "value"
                break;
            case "value":
                this.typeTarget.value = "percentage"
                break;
            default:
        }
        this.manageDisplayOfFields();
        if(event) { event.preventDefault(); }
    }

    setupInputMasks() {
        // Setup percent mask
        $(this.percentageInputTarget).inputmask(
            'decimal'
        );
    }
}
