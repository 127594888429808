/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "timeContainer", 
        "setTime"
    ]

    connect() {
        console.log("Connecting Rota Slot Controller");
        this.mangeTimeContainer();
    }

    mangeTimeContainer() {
        console.log("MTC");
        if(this.setTimeTarget.checked == true) {
            this.timeContainerTarget.style = "display: table-row;"
        } else {
            this.timeContainerTarget.style = "display: none;"
        }
    }
}
