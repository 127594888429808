import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    static targets = ["positionField"]

    connect() {
        //console.log("Connected");
        this.sortable = Sortable.create(this.element, {
            animation: 150,
            onEnd: this.end.bind(this),
        })
        this.setPositions();
    }

    end(event) {
        this.setPositions();
        console.log(event)
        this.dispatch("orderChange", { detail: {
            item: event.item,
            newIndex: event.newIndex,
            oldIndex: event.oldIndex
        }})
    }

    setPositions() {
        this.positionFieldTargets.forEach(function(element, index) {
            //console.log(element);
            //console.log(index);
            element.value = index;
        });
    }
}
