/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        'weightContainer',
        'fractionContainer',
        'quantityContainer',
        'purchaseEntireUnitContainer',
        'purchaseEntireUnit',
        'productID',
    ];

    connect() {
        console.log("Connect to purchase controller");
        this.productChanged();
    }

    productChanged() {
        console.log("Product Changed");
        type = this.productIDTarget.selectedOptions[0].dataset['type'];
        console.log(type);
        if(type == "Full") {
            this.weightContainerTarget.style              = "display: none;"
            this.fractionContainerTarget.style            = "display: none;"
            this.quantityContainerTarget.style            = "display: block;"

            this.purchaseEntireUnitContainerTarget.style  = "display: none;"
        } else if (type == "PartialWeight") {
            this.weightContainerTarget.style              = "display: block;"
            this.fractionContainerTarget.style            = "display: none;"
            this.quantityContainerTarget.style            = "display: none;"

            this.purchaseEntireUnitContainerTarget.style  = "display: block;"
        } else if (type == "PartialFraction") {
            this.weightContainerTarget.style              = "display: none;"
            this.fractionContainerTarget.style            = "display: block;"
            this.quantityContainerTarget.style            = "display: none;"

            this.purchaseEntireUnitContainerTarget.style  = "display: block;"
        } else {
            this.weightContainerTarget.style              = "display: none;"
            this.fractionContainerTarget.style            = "display: none;"
            this.quantityContainerTarget.style            = "display: none;"

            this.purchaseEntireUnitContainerTarget.style  = "display: none;"
        }
    }

    purchaseEntireUnitChanged() {
        console.log("Purchase Entire Unit Changed");
        if(this.purchaseEntireUnitTarget.checked) {
            this.weightContainerTarget.style              = "display: none;"
            this.quantityContainerTarget.style            = "display: block;"
            this.fractionContainerTarget.style            = "display: none;"
        } else {
            this.productChanged();
        }
    }
}
