/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "currentHorse",
    "newHorseContainer"
  ];

  connect() {
    //console.log("Connected");
    this.horseChanged();
  }

  horseChanged(event) {
    var horseID;
    if (event) {
      console.log("We Have Event");
      horseID = event.target.getAttribute('id').split('_').pop();
    } else {
      console.log("No Event");
      horseID = this.currentHorseTarget.innerHTML.trim();
    }

    console.log("Horse Has Changed");
    console.log("Horse ID: " + horseID.toLowerCase());

    if (horseID.toLowerCase() == "newhorse") {
      this.newHorseContainerTarget.style.display = "block";
    } else {
      this.newHorseContainerTarget.style.display = "none";
    }
  }
}
