/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'togglePreferenceState', 
        'preferencePane',
        'productRow',
        'valuePane',
        'categoryGroup'
    ];

    togglePreference() {
        if(this.togglePreferenceStateTarget.dataset.preferences == "hidden") {
            this.togglePreferenceStateTarget.setAttribute('data-preferences', 'visible');

            this.preferencePaneTargets.forEach((el, i) => { el.style.display = ''; });
            this.productRowTargets.forEach((el, i) => { el.style.display = ''; });
            this.valuePaneTargets.forEach((el, i) => {
                $(el).removeClass('col-lg-12');
                $(el).addClass('col-lg-9');
            });
        } else {
            var data = {};
            var closestHider = this.element.getAttribute("data-manager--product-picker-closestHider");

            this.preferencePaneTargets.forEach((el, i) => { el.style.display = 'none'; });
            this.togglePreferenceStateTarget.setAttribute('data-preferences', 'hidden');
            this.productRowTargets.forEach((el, i) => { 
                if($(el).find("input:checkbox")[0].checked) {
                    data[$(el).closest(closestHider)[0].id.substring(3)] = true
                    el.style.display = '';
                } else {
                    data[$(el).closest(closestHider)[0].id.substring(3)] = false
                    el.style.display = 'none';
                }
            });
            this.valuePaneTargets.forEach((el, i) => {
                $(el).removeClass('col-lg-9');
                $(el).addClass('col-lg-12');
            });

            // Now we need to send all the information
            $.ajax({
                type: "POST",
                url: this.element.getAttribute("data-manager--product-picker-prefurl"),
                data: {
                    "rowData": data,
                    "categoryData": this.buildOrder(),
                },
                success: function(return_date) {
                    update_button_wait = false
                }
            });
        }
    }

    moveCategoryUp(event) {
        event.preventDefault();
        var element = event.srcElement.closest("div[data-manager--product-picker-target]");
        if(element.previousElementSibling) {
          element.parentNode.insertBefore(element, element.previousElementSibling);
        }
    }

    moveCategoryDown(event) {
        event.preventDefault();
        var element = event.srcElement.closest("div[data-manager--product-picker-target]");
        if(element.nextElementSibling) {
          element.parentNode.insertBefore(element.nextElementSibling, element);
        }
    }

    buildOrder() {
        var order = [];
        this.categoryGroupTargets.forEach(function(group) {
            order.push(group.dataset['catId']);
        });
        return order
    }
}
