/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        'questionContainer',
        'associatedQuestion'
    ];

    connect() {
        console.log("Connecting to Defined Use Controller");
        this.manageDisplayOfQuestionContainer();
    }

    manageDisplayOfQuestionContainer() {
        if(this.associatedQuestionTarget.checked == true) {
            this.questionContainerTarget.style = "display: table-row;"
        } else {
            this.questionContainerTarget.style = "display: none;"
        }
    }
}
