/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'currentStep',
        'lastStep',
        'stage',
        'submit',
        'next'
    ];

    connect() {
        console.log("Connected End Of Day Form");

        // Set initial display values
        this.stageTargets.forEach(function(element) { element.style.display = "none" });
        this.stageTargets[this.getCurrentStep()].style.display = "block";

        this.displayCurrentSlide();
    }

    next() {
        this.currentStepTarget.innerHTML = this.getCurrentStep() + 1;
        this.displayCurrentSlide();
    }

    getCurrentStep() {
        return parseInt(this.currentStepTarget.innerHTML);
    }

    getLastStep() {
        return parseInt(this.lastStepTarget.innerHTML);
    }

    displayCurrentSlide() {
        if(this.getCurrentStep() == 0) {
            this.slideInStage(this.stageTargets[this.getCurrentStep()]);
        } else {
            $(this.stageTargets[this.getCurrentStep() - 1]).slideUp("slow", this.slideInStage(
                this.stageTargets[this.getCurrentStep()]
            ));
        }

        if(this.getCurrentStep() == this.getLastStep()) {
            this.submitTarget.style.display = "block";
            this.nextTarget.style.display = "none";
        } else {
            this.submitTarget.style.display = "none";
            this.nextTarget.style.display = "block";
        }
    }

    slideInStage(stageElement) {
        $(this.stageTargets[this.getCurrentStep()]).slideDown("slow");
    }
}
