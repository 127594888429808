/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["rotaRow"]

    connect() {
        console.log("rota_autofill_controller connected");
    }

    autoFill(event) {
        this.autoFillWithoutEvent();
        event.preventDefault();
    }

    autoFillWithoutEvent() {
        console.log("autoFillWithoutEvent");

        this.rotaRowTargets.forEach((row, index) => {
            event = new CustomEvent("autoFillTrigger", { detail: { startOffset: index } })
            row.dispatchEvent(event);
        });
    }
}
