/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/features/weak-map"
import "core-js/features/array/filter"
import "core-js/features/string/includes"
import "babel-polyfill"

import "@stimulus/polyfills"
import 'whatwg-fetch'
import "@hotwired/turbo-rails"
window.Turbo = Turbo

window.fetchATY = function(url) {
    return fetch(url,
        {
              credentials: 'same-origin'
        })
        .then( response => {
            console.log(response);
            if(response.redirected == true) {
                window.location.replace(response.url);
            } else {
                return response.text();
            }
        });
}

window.fetchATYPlain = function(url) {
    return fetch(url,
        {
              credentials: 'same-origin'
        })
        .then( response => {
            console.log(response);
            if(response.redirected == true) {
                window.location.replace(response.url);
            } else {
                return response;
            }
        });
}

//window.setPreference = function(key, value) {
//  return fetch("<%= user_preference_key_store_path %>", 
//    { 
//      method: 'POST'
//      body: JSON.stringify({
//        key: key, 
//        value: value
//      })
//    }
//  );
//}

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

require("trix")
require("@rails/actiontext")

/*
document.documentElement.addEventListener('turbo:submit-end', (event) => {
    if( event.detail.fetchResponse.redirected == true ) {
        $("#modalDiag").modal('hide');
    }
});

*/
