/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String }

    static targets = [
        'date',
        'serviceID',
        'historicBilling',
        'nextBillingDateBlock',
        'historicBillingInputBlock'
    ];

    connect() {
        console.log("Connect to NextBillingDateController");
        this.dateChanged();
        this.update();
    }

    update() {
        console.log("Changed");
        console.log(this.urlValue);

        fetch(this.urlValue + "?" + new URLSearchParams({
            service_id: this.serviceIDTarget.value,
            date: this.dateTarget.value,
            historic_billing: this.historicBillingTarget.value
        })).then(response => {
            return response.text()
        }).then(html => {
            this.nextBillingDateBlockTarget.innerHTML = html;
        });
    }

    dateChanged() {
        console.log("Date changed");

        var date = new Date(this.dateTarget.value);
        date.setHours(0, 0, 0, 0);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date < today) {
            this.historicBillingInputBlockTarget.classList.remove("hidden");
        } else {
            this.historicBillingInputBlockTarget.classList.add("hidden");
        }

        this.update();
    }
}
