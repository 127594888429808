/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'togglePreferenceState', 
        'preferencePane',
        'prefCell',
        'valuePane',
        'toggleValue'
    ];

    /* This is used to set the columns along the top */

    connect() {
        console.log("Stock Order Connected")
    }

    togglePreference() {
        if(this.togglePreferenceStateTarget.dataset.settingsPreferences == "hidden") {
            this.togglePreferenceStateTarget.setAttribute('data-settings-preferences', 'visible');
            this.preferencePaneTargets.forEach((el, i) => { el.style.display = ''; });
            this.prefCellTargets.forEach((el, i) => { el.style.display = ''; });
        } else {
            //Hide the preference code
            this.preferencePaneTargets.forEach((el, i) => { el.style.display = 'none'; });
            this.togglePreferenceStateTarget.setAttribute('data-settings-preferences', 'hidden');

            var data = {};
            this.toggleValueTargets.forEach((el, i) => { 
                var prefType = el.id.substring(17);
                if(el.checked) {
                    data[prefType] = true;
                    //el.style.display = '';
                    this.prefCellTargets.forEach((el, i) => { 
                        if(el.dataset.use_type == prefType) {
                            el.style.display = ''; 
                        }
                    });
                } else {
                    data[prefType] = false;
                    //el.style.display = 'none';
                    this.prefCellTargets.forEach((el, i) => { 
                        if(el.dataset.use_type == prefType) {
                            el.style.display = 'none'; 
                        }
                    });
                }
            });
            //console.log(data);

            // Now we need to send all the information
            $.ajax({
                type: "POST",
                url: this.element.getAttribute("data-manager--stock-order-preferences-prefurl"),
                data: {"data": data},
                success: function(return_date) { }
            });


            //Trigger change on row so it'll recalculate the biggest value
            /*
            var event = document.createEvent('HTMLEvents');
            event.initEvent('forcedChange', true, true);
            $( ".product_row" ).each(function() {
              this.dispatchEvent(event);
            });
            */
        }
    }
}
