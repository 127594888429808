import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "timeContainer",
        "setTime"
    ]

    connect() {
        //console.log("Rota Cell Controller");
        this.manageTimeInputDisplay();
    }

    toggleTimeInput(event) {
        event.preventDefault();
        console.log("toggleTimeInput");

        if(  this.setTimeTarget.value == "false" ) {
            this.setTimeTarget.value = "true";
        } else {
            this.setTimeTarget.value = "false";
        }
        this.manageTimeInputDisplay();

    }

    manageTimeInputDisplay() {
        if(  this.setTimeTarget.value == "false" ) {
            this.timeContainerTarget.classList.add("hidden");
        } else {
            this.timeContainerTarget.classList.remove("hidden");
        }
    }
}
