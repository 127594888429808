/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "calendarGroupItem",
        "keyPill"
    ];

    connect() {
        //console.log("Connected to calendar controller");

        var outsideThis = this;
        this.keyPillTargets.forEach(function(e) {
            var result = Cookies.get(e.dataset["pillKey"])
            //console.log(e.dataset["pillKey"] + " " + result);
            var toCheck;
            if(typeof result == "undefined") {
                e.checked = true;
                toCheck = true;
            } else if( result == "true") {
                e.checked = true;
                toCheck = true;
            } else {
                e.checked = false;
                toCheck = false;
            }

            //e.parentElement.dispatchEvent(new Event("click"));
            //e.parentElement.dispatchEvent(new Event("click"));
            outsideThis.managePill(e, toCheck);
        });

        window.setTimeout(function() {
            outsideThis.calendarGroupItemTargets.forEach(function(e) {
                //console.log("Dispatching event");
                e.dispatchEvent(new Event("update"));
            })
        }, 100);
    }

    pillClick(event) {
        var clicked = event.target;
        this.managePill(clicked, clicked.checked);
    }

    managePill(clicked, checked) {
        //console.log("Pill Click");

        if(checked == false) {
            Cookies.set(clicked.dataset["pillKey"], false)

            this.calendarGroupItemTargets.forEach(function(e) {
                //console.log(e.dataset["pillClass"]);
                //console.log(clicked.dataset["pillKey"]);
                //console.log((e.dataset["pillClass"] == clicked.dataset["pillKey"]));

                if(e.dataset["pillClass"] == clicked.dataset["pillKey"]) {
                    //console.log("Hiding");
                    e.style.display = "none";
                    e.setAttribute("data-show", "false");
                }
            });
        } else {
            Cookies.set(clicked.dataset["pillKey"], true)

            this.calendarGroupItemTargets.forEach(function(e) {
                //console.log(e.dataset["pillClass"]);
                //console.log((e.dataset["pillClass"] == clicked.dataset["pillKey"]));

                if(e.dataset["pillClass"] == clicked.dataset["pillKey"]) {
                    //console.log("Showing filtering");
                    e.setAttribute("data-show", "true");
                    if(e.tagName == "SPAN") {
                        e.style.display = "inline";
                    } else if(e.tagName == "TR") {
                        e.style.display = "table-row";
                    } else {
                        e.style.display = "inline-block";
                    }
                }
            });
        }

        this.calendarGroupItemTargets.forEach(function(e) {
            //console.log("Dispatching event");
            e.dispatchEvent(new Event("update"));
        });
    }
}
