import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "nonSubmitButton",
    "commit",
  ]

  connect() {
    console.log("Pricing Matrix Controller");

    this.timer = 0;
  }

  updateWithoutConfirmation(event) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.commitTarget.value = '0';
      this.nonSubmitButtonTarget.click();
    }, 500);
  }
}
